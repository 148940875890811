const toLowerCase = (string, locale) => string.toLocaleLowerCase(locale);
const toUpperCase = (string, locale) => string.toLocaleUpperCase(locale);
const toTemplate = (string, values) => string.replace(/\$\{(\w+)\}/g, ($0, $1) => values[$1] ?? $0);
const toSentenceCase = (string, locale) => {
  if (!string) return "";
  const [firstChar, ...chars] = string;
  return toUpperCase(firstChar, locale) + chars.join("");
};
const toTitleCase = (string, locale) => string.split(" ").map((part) => toSentenceCase(part, locale)).join(" ");
const toQuestion = (string, locale) => locale?.startsWith("es") ? "¿" + string + "?" : string + "?";

export { toLowerCase, toQuestion, toSentenceCase, toTemplate, toTitleCase, toUpperCase };
